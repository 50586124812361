var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pwd-success df fdc alc" },
    [
      _c("icon-svg", {
        staticClass: "main-color success-svg",
        attrs: { "icon-class": "yi_icon_xuanze" },
      }),
      _c("div", [_vm._v("修改支付密码成功")]),
      _vm.is_detail
        ? _c("div", { staticClass: "btn-box df fdr alc jc-sb" }, [
            _c("div", { staticClass: "detail-btn" }, [_vm._v("返回商品详情")]),
            _c("div", { staticClass: "setting-btn" }, [_vm._v("返回设置")]),
          ])
        : _c("div", { staticClass: "btn-box df fdr alc jcc" }, [
            _c(
              "div",
              { staticClass: "setting-btn", on: { click: _vm.backSetting } },
              [_vm._v("返回设置")]
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }