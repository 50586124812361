<template>
  <div class="pwd-success df fdc alc">
    <icon-svg class="main-color success-svg" icon-class="yi_icon_xuanze"/>
    <div>修改支付密码成功</div>
    <div v-if="is_detail" class="btn-box df fdr alc jc-sb">
      <div class="detail-btn">返回商品详情</div>
      <div class="setting-btn">返回设置</div>
    </div>
    <div v-else class="btn-box df fdr alc jcc">
      <div class="setting-btn" @click="backSetting">返回设置</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "success",
  data() {
    return {
      is_detail: false
    }
  },
  methods: {
    backSetting() {
      this.$router.go(-3)
    },
  }
}
</script>

<style scoped lang="less">
@import "../../../../common/less/variable.less";
@import "../../../../common/less/mixin.less";

.pwd-success {
  .wh(100%, 100vh);
  background: @color-white;;
  padding: 0.7rem 0.74rem;
  .sc(0.36rem, #333);
  .success-svg{
    width: 2.2rem !important;
    height: 2.2rem !important;
    margin-bottom: 0.3rem;
  }

  .btn-box {
    margin-top: 0.64rem;
    width: 100%;

    .detail-btn, .setting-btn {
      .wh(2.62rem, 0.68rem);
      line-height: 0.68rem;
      border-radius: 0.44rem;
      text-align: center;
    }

    .detail-btn {
      .sc(0.32rem, var(--main-color));
      border: 0.02rem solid var(--main-color);
    }

    .setting-btn {
      .sc(0.32rem, @color-grey);
      border: 0.02rem solid @color-grey;


    }
  }
}

</style>